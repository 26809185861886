var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative"
  }, [_vm.formData.details.length ? _c('div', {
    staticClass: "sticky top-0 left-0 z-10 w-full flex items-center justify-between mt-0 px-6 py-4",
    class: Math.sign(_vm.formData.amount) == -1 ? 'bg-red-200' : 'bg-teal-200'
  }, [_c('div', {
    staticClass: "hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
  }, [_c('div', {
    staticClass: "md:flex items-center justify-between text-base text-blue-600 pt-4 md:pt-0"
  }, [_c('svg', {
    staticClass: "h-8 w-8 mr-4 inline",
    class: Math.sign(_vm.formData.amount) == -1 ? 'text-red-600' : 'text-teal-600',
    attrs: {
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
      "clip-rule": "evenodd"
    }
  })]), _c('div', {
    staticClass: "text-lg inline",
    class: Math.sign(_vm.formData.amount) == -1 ? 'text-red-800' : 'text-teal-800'
  }, [_vm._v(" " + _vm._s(_vm.formData.details.length + " orders selected") + " ")])])]), _c('div', {
    staticClass: "order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4"
  }, [_c('div', {
    staticClass: "auth flex items-center w-full md:w-full"
  }, [_c('div', {
    staticClass: "font-bold text-lg inline",
    class: Math.sign(_vm.formData.amount) == -1 ? 'text-red-800' : 'text-teal-800'
  }, [_vm._v(" " + _vm._s(_vm.$t("total_amount")) + ": "), _vm._l(_vm.formData.amountCurrencySame, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.amount.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrencySame.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  })], 2)])])]) : _vm._e(), _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow form-payement"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-2"
  }, [_c('div', {
    staticClass: "flex w-1/3"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/payments"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_payement")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "w-full flex justify-end space-x-4"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopPayMethode('open', 'selectOrders');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("pay")) + " ")])])]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6 pt-2"
  }, [_c('div', {
    staticClass: "mb-6 border p-4 border-dotted rounded-md border-gray-500"
  }, [_c('div', {
    staticClass: "flex w-full space-x-2"
  }, [_c('div', {
    staticClass: "flex w-1/2 items-center justify-center"
  }, [_c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "border w-full h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("select_seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "options": _vm.sellers
    },
    on: {
      "input": function input($event) {
        return _vm.getOrdersBySeller($event, 'seller');
      },
      "search": function search($event) {
        return _vm.searchSellers($event, 'seller');
      }
    },
    model: {
      value: _vm.formData.seller,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "seller", $$v);
      },
      expression: "formData.seller"
    }
  })], 1)]), _c('div', {
    staticClass: "border w-full h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("warehouses")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.warehouses,
      "multiple": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getOrdersBySeller($event, 'warehouses');
      }
    },
    model: {
      value: _vm.formData.warehouses,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "warehouses", $$v);
      },
      expression: "formData.warehouses"
    }
  })], 1)])])]), _c('div', {
    staticClass: "flex w-1/2"
  }, [_c('div', {
    staticClass: "px-3 w-full"
  }, [_c('div', {
    staticClass: "inline-block w-full"
  }, [_c('span', {
    staticClass: "block mb-3"
  }, [_vm._v("Date shipping paid:")]), _c('div', {
    staticClass: "inline-block w-full pr-1"
  }, [_c('date-picker', {
    staticClass: "w-full-imp shadow",
    attrs: {
      "placeholder": "Range date ...",
      "format": "YYYY-MM-DD",
      "type": "date",
      "range": ""
    },
    model: {
      value: _vm.intervalDates.from_Date,
      callback: function callback($$v) {
        _vm.$set(_vm.intervalDates, "from_Date", $$v);
      },
      expression: "intervalDates.from_Date"
    }
  })], 1), _c('div', {
    staticClass: "block text-center w-full pr-1 pt-2"
  }, [_c('button', {
    staticClass: "mr-2 w-3/12 inline-block relative loidingconfirm px-4 py-2 text-white font-light tracking-wider bg-green-700 rounded",
    attrs: {
      "disabled": !_vm.intervalDates.from_Date || _vm.loadingFind
    },
    on: {
      "click": _vm.FindOrderByBetweenDate
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingFind,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingFind = $event;
      }
    }
  }), _vm._v(" Find ")], 1), _vm.formData.seller ? _c('button', {
    staticClass: "px-2 mr-2 w-3/12 inline-block py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-yellow-600 relative loidingconfirm rounded",
    attrs: {
      "disabled": _vm.loadingRefresh
    },
    on: {
      "click": _vm.refresh
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingRefresh,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingRefresh = $event;
      }
    }
  }), _vm._v(" Refresh "), _c('i', {
    staticClass: "material-icons align-bottom"
  }, [_vm._v("refresh")])], 1) : _vm._e(), _c('button', {
    staticClass: "w-3/12 inline-block text-center px-2 py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-green-600 rounded relative loidingconfirm",
    attrs: {
      "disabled": _vm.loadingReset
    },
    on: {
      "click": _vm.reset
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingReset,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingReset = $event;
      }
    }
  }), _c('svg', {
    staticClass: "fill-current inline-block",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"
    }
  })])], 1)])])])])])]), _c('div', [_vm.orders.length > 0 ? _c('div', {
    staticClass: "mt-5 overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative"
  }, [_c('div', {
    staticClass: "w-full bg-white mt-1 mb-5 shadow-lg border overflow-hidden"
  }, [_c('div', {
    staticClass: "py-2 text-center font-bold uppercase tracking-wide text-white bg-green-700"
  }, [_vm._v(" Seller: " + _vm._s(_vm.formData.seller ? _vm.formData.seller.fullName : "") + " ")]), _c('div', {
    staticClass: "flex items-center justify-between py-2 px-3 bg-gray-400"
  }, [_c('h1', {
    staticClass: "text-gray-800 font-bold"
  }, [_vm._l(_vm.totalOrdersAmountFees().dataArray, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.amount.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.totalOrdersAmountFees().dataArray.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), _c('span', [_vm._v("(Total orders: " + _vm._s(_vm.totalOrdersAmountFees().count) + ")")])], 2), _c('button', {
    staticClass: "bg-gray-800 text-xs text-white px-5 py-1 font-semibold rounded uppercase hover:bg-gray-700 relative loading-amount",
    class: _vm.loadingShowPay ? 'disabled-loading' : '',
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopPayMethode('open', 'allOrders');
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingShowPay,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingShowPay = $event;
      }
    }
  }), _vm._v("Pay all ")], 1)])]), _c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('table', {
    staticClass: "border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
  }, [_c('thead', [_c('tr', {
    staticClass: "text-left"
  }, [_c('th', {
    staticClass: "py-1 px-3 sticky top-0 border-b border-gray-200 bg-green-700 text-white border-gray-300"
  }, [_c('label', {
    staticClass: "text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
  }, [_c('input', {
    staticClass: "form-checkbox checkallbbox focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "checkbox"
    },
    on: {
      "click": function click($event) {
        return _vm.selectAllCheckbox($event);
      }
    }
  })])]), _vm._l(_vm.headings, function (heading, ind) {
    return _c('th', {
      key: ind,
      staticClass: "text-center px-1 text-xss font-bold uppercase bg-green-700 text-white border border-gray-300"
    }, [_vm._v(" " + _vm._s(heading.value) + " ")]);
  })], 2)]), _vm._l(_vm.orders, function (order, ind) {
    return _c('tableBody', {
      key: ind,
      attrs: {
        "item": order,
        "servicesSeller": _vm.servicesSeller,
        "options": _vm.dataCountry,
        "warehouses": _vm.warehouses,
        "citiesPrices": _vm.citiesPrices,
        "warehouse": _vm.warehouse,
        "idWharhouse": _vm.idWharhouse,
        "qu_control": _vm.quality_control,
        "confvat": _vm.confirmation_vat,
        "fulvat": _vm.fulfillement_fees_vat,
        "ship_vat": _vm.shipping_vat,
        "ship_refund_vat": _vm.shipping_refund_vat,
        "ship_cancel_vat": _vm.shipping_cancel_vat,
        "formData": _vm.formData,
        "settingsFees": _vm.settingsFees
      },
      on: {
        "getRowDetail": _vm.getRowDetail,
        "UpdateRowFees": _vm.UpdateRowFees
      }
    });
  })], 2), _vm.expeditions.length > 0 ? _c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider",
    attrs: {
      "colspan": 7
    }
  }, [_vm._v(" Expeditions ")])])]), _c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }), _c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("nb_packages")) + " ")]), _c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("transport_mode")) + " ")]), _c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("details")) + " ")]), _c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("country")) + " ")]), _c('th', {
    staticClass: "text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("expedition_fees")) + " ")])])]), _c('tbody', {
    staticClass: "bg-white"
  }, _vm._l(_vm.expeditions, function (exp, ind) {
    return _c('tr', {
      key: ind
    }, [_c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b-2 border-r-2 text-center"
    }, [_c('input', {
      staticClass: "form-checkbox checkbox-exp focus:outline-none focus:shadow-outline",
      attrs: {
        "type": "checkbox",
        "id": exp._id
      },
      on: {
        "click": function click($event) {
          return _vm.getRowDetailExp($event, exp, exp._id);
        }
      }
    })]), _c('td', {
      staticClass: "px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.numberOfPackages) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.transportMode) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.details.length) + " " + _vm._s(_vm.$t("products")) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.country) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
    }, [_c('div', {
      staticClass: "text-sm leading-5 font-bold text-blue-900 capitalize"
    }, [_vm._v(" " + _vm._s(exp.fees) + " ")])])]);
  }), 0)]) : _vm._e()], 1) : _vm._e(), _vm.orders.length == 0 ? _c('div', {
    staticClass: "mt-6",
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_vm._m(0), _c('loading', {
    attrs: {
      "active": _vm.loadingorderbyseller,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingorderbyseller = $event;
      }
    }
  }), _vm._l(_vm.ordersByseller, function (totalorders, index) {
    return _c('tableTotalAmount', {
      key: index,
      attrs: {
        "sellerSelect": _vm.sellerSelect,
        "country": _vm.formData.warehouses.map(function (el) {
          return el.country;
        }),
        "loadingShowPay": _vm.loadingShowPay,
        "item": totalorders,
        "citiesPrices": _vm.citiesPrices,
        "warehouse": _vm.warehouse,
        "idWharhouse": _vm.idWharhouse
      },
      on: {
        "getOrdersBySeller": _vm.getOrdersBySeller,
        "OpenClosePopPayMethode": _vm.OpenClosePopPayMethode
      }
    });
  })], 2)]) : _vm._e(), !_vm.formData.seller && _vm.ordersByseller.length == 0 ? _c('div', {
    staticClass: "relative bg-blue-100 py-8 mt-1"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._m(1)], 1) : _vm._e(), _vm.formData.seller && _vm.orders.length == 0 && _vm.formData.details.length == 0 && _vm.ordersByseller.length == 0 ? _c('div', {
    staticClass: "relative bg-blue-100 py-8 mt-1"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._m(2)], 1) : _vm._e(), _vm.paginate.lastpage > 1 ? _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "flex flex-col items-center my-12"
  }, [_vm.paginate.currentpage < _vm.paginate.lastpage ? _c('button', {
    staticClass: "custom-btn btn-16",
    attrs: {
      "disabled": _vm.paginate.currentpage == _vm.paginate.lastpage
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v(" Show more data ")]) : _vm._e()])]) : _vm._e()])])])])]), _c('vue-modal-2', {
    attrs: {
      "name": "ModalPay",
      "headerOptions": {
        title: _vm.$t('payement_methode')
      },
      "noFooter": true,
      "modalSize": "lg"
    }
  }, [_c('vue-ins-progress-bar'), _c('loading', {
    attrs: {
      "active": _vm.loadingPay,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingPay = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "w-96 rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/3 pt-6 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-green-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("payment")])])]), _c('div', {
    staticClass: "w-full pr-4"
  }, [_c('h3', {
    staticClass: "font-bold text-green-600 mb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("payement_methode")) + " ")]), _c('div', {
    staticClass: "w-full px-4"
  }, [_c('div', {
    staticClass: "mb-5 border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("payement_methode")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.options,
      "label": "name"
    },
    on: {
      "input": _vm.setSelected
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.getImagePay(option.name)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.method,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "method", $$v);
      },
      expression: "formData.method"
    }
  })], 1)])]), _c('table', {
    staticClass: "invoice-table table table-borderless table-nowrap mb-0 w-full"
  }, [_c('thead', {
    staticClass: "align-middle"
  }, [_c('tr', [_c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th')])]), _c('tbody', [_c('tr', {
    staticClass: "border-top border-top-dashed mt-2"
  }, [_c('td', {
    staticClass: "w-1/6",
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "p-0",
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticClass: "table table-borderless table-sm table-nowrap align-middle mb-0 w-full"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("Seller")]), _c('td', {
    staticStyle: {
      "width": "150px"
    }
  }, [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.seller ? _vm.formData.seller.fullName : "") + " ")])])]), _vm.intervalDates.from_Date && _vm.filters.from_Date ? _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("Période Date")]), _c('td', {
    staticStyle: {
      "width": "150px"
    }
  }, [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, [_vm._v(" [" + _vm._s(_vm.filters.from_Date) + "] ")])])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("Warehouses")]), _c('td', {
    staticStyle: {
      "width": "150px"
    }
  }, [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, [_c('div', {
    staticClass: "flex leading-5 capitalize rounded-full"
  }, _vm._l(_vm.formData.warehouses.map(function (el) {
    return el.flag;
  }), function (images, r) {
    return _c('span', {
      key: r
    }, [_c('img', {
      staticClass: "w-4 h-4 rounded-full mr-2",
      attrs: {
        "src": images
      }
    })]);
  }), 0)])])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v(" Sub Total "), _c('small', [_vm._v("(" + _vm._s(_vm.formData.details.length) + " orders)")])]), _c('td', {
    staticStyle: {
      "width": "150px"
    }
  }, [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, _vm._l(_vm.formData.amountCurrencySame, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.totalPrice.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrencySame.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0)])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("Total Fees")]), _c('td', [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, _vm._l(_vm.formData.amountCurrencySame, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.fees.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrencySame.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0)])]), _c('tr', {
    staticClass: "border-top border-top-dashed"
  }, [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("Total Amount")]), _c('td', [_c('div', {
    staticClass: "form-control bg-light border-0",
    attrs: {
      "readonly": ""
    }
  }, _vm._l(_vm.formData.amountCurrencySame, function (price, j) {
    return _c('span', {
      key: j
    }, [_vm._v(" " + _vm._s(price.amount.toFixed(2)) + " "), _c('sup', [_vm._v(_vm._s(price.currency))]), j != _vm.formData.amountCurrencySame.length - 1 ? _c('span', [_vm._v("|")]) : _vm._e()]);
  }), 0)])])])])])])])])])]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopPayMethode('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t("confirm")))])])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "relative"
  }, [_c('tr', {
    staticClass: "bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
  }, [_c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Seller ")])]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Country ")])]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
  }, [_c('span', [_vm._v(" Number of orders ")])]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
  }, [_c('span', [_vm._v(" Amount to pay ")])]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
  }, [_c('span', [_vm._v(" Action ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', [_vm._v("No order to paid ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', [_vm._v("No order to paid for this Seller")])])]);

}]

export { render, staticRenderFns }