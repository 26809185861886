<template>
  <div class="relative">
    <div
      v-if="formData.details.length"
      class="sticky top-0 left-0 z-10 w-full flex items-center justify-between mt-0 px-6 py-4"
      :class="Math.sign(formData.amount) == -1 ? 'bg-red-200' : 'bg-teal-200'"
    >
      <div
        class="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
      >
        <div
          class="md:flex items-center justify-between text-base text-blue-600 pt-4 md:pt-0"
        >
          <svg
            :class="
              Math.sign(formData.amount) == -1
                ? 'text-red-600'
                : 'text-teal-600'
            "
            class="h-8 w-8 mr-4 inline"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <div
            :class="
              Math.sign(formData.amount) == -1
                ? 'text-red-800'
                : 'text-teal-800'
            "
            class="text-lg inline"
          >
            {{ formData.details.length + " orders selected" }}
          </div>
        </div>
      </div>
      <div
        class="order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4"
      >
        <div class="auth flex items-center w-full md:w-full">
          <div
            :class="
              Math.sign(formData.amount) == -1
                ? 'text-red-800'
                : 'text-teal-800'
            "
            class="font-bold text-lg inline"
          >
            {{ $t("total_amount") }}:
            <!-- <span>{{(formData.amount).toFixed(2)}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></span> -->

            <span v-for="(price, j) in formData.amountCurrencySame" :key="j">
              {{ price.amount.toFixed(2) }} <sup>{{ price.currency }}</sup>
              <span v-if="j != formData.amountCurrencySame.length - 1">|</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 items-center">
      <div class="bg-white w-fullrounded-lg shadow form-payement">
        <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
          <div class="flex w-1/3">
            <router-link
              :class="$colors.primary"
              class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
              to="/payments"
            >
              <i class="material-icons mx-2">chevron_left</i>
            </router-link>
            <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
              {{ $t("new_payement") }}
            </div>
          </div>
          <div class="w-full flex justify-end space-x-4">
            <button
              @click="OpenClosePopPayMethode('open', 'selectOrders')"
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
            >
              {{ $t("pay") }}
            </button>
          </div>
        </div>
        <div class="">
          <div class="bg-white rounded-lg p-6 pt-2">
            <div
              class="mb-6 border p-4 border-dotted rounded-md border-gray-500"
            >
              <div class="flex w-full space-x-2">
                <div class="flex w-1/2 items-center justify-center">
                  <div class="w-full space-y-4">
                    <div
                      class="border w-full h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                    >
                      <div
                        class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                      >
                        <p>
                          <label
                            for="seller"
                            class="bg-white text-gray-700 px-1"
                            >{{ $t("select_seller") }}
                            <span :class="$colors.required">*</span></label
                          >
                        </p>
                      </div>
                      <p>
                        <v-select
                          @input="getOrdersBySeller($event, 'seller')"
                          @search="searchSellers($event, 'seller')"
                          label="fullName"
                          v-model="formData.seller"
                          :options="sellers"
                        ></v-select>
                      </p>
                    </div>
                    <div
                      class="border w-full h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                    >
                      <div
                        class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                      >
                        <p>
                          <label
                            for="seller"
                            class="bg-white text-gray-700 px-1"
                            >{{ $t("warehouses") }}
                            <span :class="$colors.required">*</span></label
                          >
                        </p>
                      </div>
                      <p>
                        <v-select
                          label="name"
                          @input="getOrdersBySeller($event, 'warehouses')"
                          v-model="formData.warehouses"
                          :options="warehouses"
                          multiple
                        ></v-select>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex w-1/2">
                  <div class="px-3 w-full">
                    <div class="inline-block w-full">
                      <span class="block mb-3">Date shipping paid:</span>
                      <div class="inline-block w-full pr-1">
                        <date-picker
                          placeholder="Range date ..."
                          format="YYYY-MM-DD"
                          type="date"
                          class="w-full-imp shadow"
                          v-model="intervalDates.from_Date"
                          range
                        ></date-picker>
                      </div>
                      <div class="block text-center w-full pr-1 pt-2">
                        <button
                          @click="FindOrderByBetweenDate"
                          :disabled="!intervalDates.from_Date || loadingFind"
                          class="mr-2 w-3/12 inline-block relative loidingconfirm px-4 py-2 text-white font-light tracking-wider bg-green-700 rounded"
                        >
                          <loading
                            :active.sync="loadingFind"
                            :is-full-page="fullPage"
                          ></loading>
                          Find
                        </button>
                        <button
                          v-if="formData.seller"
                          @click="refresh"
                          class="px-2 mr-2 w-3/12 inline-block py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-yellow-600 relative loidingconfirm rounded"
                          :disabled="loadingRefresh"
                        >
                          <loading
                            :active.sync="loadingRefresh"
                            :is-full-page="fullPage"
                          ></loading>
                          Refresh
                          <i class="material-icons align-bottom">refresh</i>
                        </button>
                        <button
                          @click="reset"
                          class="w-3/12 inline-block text-center px-2 py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-green-600 rounded relative loidingconfirm"
                          :disabled="loadingReset"
                        >
                          <loading
                            :active.sync="loadingReset"
                            :is-full-page="fullPage"
                          ></loading>

                          <svg
                            class="fill-current inline-block"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="w-full" v-if="formData.seller">
                        <div class="inline-block w-1/3 pr-1">
                            <div class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-green-500">*</span></label></p></div>
                                <p>  <input  v-model="intervalDates.from_Date" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                            </div>
                        </div>
                            <div class="inline-block w-1/3 pr-1">
                            <div class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-green-500">*</span></label></p></div>
                                <p>  <input  v-model="intervalDates.to_Date" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                            </div>
                        </div>
                        <div class="inline-block text-right w-1/3 pr-1">
                            <div class="justify-center flex items-center">
                                <button @click="FindOrderByBetweenDate" :disabled="intervalDates.from_Date==null || intervalDates.to_Date==null" class="w-2/5 mr-2 px-2 py-2 text-white font-light tracking-wider bg-green-700 rounded">Find</button>
                                <button @click="refresh"  class="w-1/4 text-center mr-2 px-2 py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-green-600 rounded">Reset</button>
                                <button @click="reset"  class="w-1/4 text-center px-2 py-2 text-green-500 hover:text-white font-light tracking-wider bg-gray-300 hover:bg-green-600 rounded">
                                  <svg class="fill-current inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"/></svg>
                                </button>
                            </div>
                           
                        </div>
                    </div>  -->
            </div>
            <div>
              <!-- Order selectioner -->
              <div
                v-if="orders.length > 0"
                class="mt-5 overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative"
              >
                <div
                  class="w-full bg-white mt-1 mb-5 shadow-lg border overflow-hidden"
                >
                  <div
                    class="py-2 text-center font-bold uppercase tracking-wide text-white bg-green-700"
                  >
                    Seller:
                    {{ formData.seller ? formData.seller.fullName : "" }}
                  </div>
                  <div
                    class="flex items-center justify-between py-2 px-3 bg-gray-400"
                  >
                    <h1 class="text-gray-800 font-bold">
                      <span
                        v-for="(price, j) in totalOrdersAmountFees().dataArray"
                        :key="j"
                      >
                        {{ price.amount.toFixed(2) }}
                        <sup>{{ price.currency }}</sup>
                        <span
                          v-if="
                            j != totalOrdersAmountFees().dataArray.length - 1
                          "
                          >|</span
                        >
                      </span>
                      <span
                        >(Total orders:
                        {{ totalOrdersAmountFees().count }})</span
                      >
                    </h1>
                    <button
                      @click="OpenClosePopPayMethode('open', 'allOrders')"
                      class="bg-gray-800 text-xs text-white px-5 py-1 font-semibold rounded uppercase hover:bg-gray-700 relative loading-amount"
                      :class="loadingShowPay ? 'disabled-loading' : ''"
                    >
                      <loading
                        :active.sync="loadingShowPay"
                        :is-full-page="fullPage"
                      ></loading
                      >Pay all
                    </button>
                  </div>
                </div>
                <loading
                  :active.sync="loading"
                  :is-full-page="fullPage"
                ></loading>
                <table
                  class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
                >
                  <thead>
                    <tr class="text-left">
                      <th
                        class="py-1 px-3 sticky top-0 border-b border-gray-200 bg-green-700 text-white border-gray-300"
                      >
                        <label
                          class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            class="form-checkbox checkallbbox focus:outline-none focus:shadow-outline"
                            @click="selectAllCheckbox($event)"
                          />
                        </label>
                      </th>
                      <th
                        v-for="(heading, ind) in headings"
                        :key="ind"
                        class="text-center px-1 text-xss font-bold uppercase bg-green-700 text-white border border-gray-300"
                      >
                        {{ heading.value }}
                      </th>
                    </tr>
                  </thead>
                  <tableBody
                    v-for="(order, ind) in orders"
                    :key="ind"
                    :item="order"
                    :servicesSeller="servicesSeller"
                    :options="dataCountry"
                    :warehouses="warehouses"
                    :citiesPrices="citiesPrices"
                    :warehouse="warehouse"
                    :idWharhouse="idWharhouse"
                    :qu_control="quality_control"
                    :confvat="confirmation_vat"
                    :fulvat="fulfillement_fees_vat"
                    :ship_vat="shipping_vat"
                    :ship_refund_vat="shipping_refund_vat"
                    :ship_cancel_vat="shipping_cancel_vat"
                    :formData="formData"
                    :settingsFees="settingsFees"
                    @getRowDetail="getRowDetail"
                    @UpdateRowFees="UpdateRowFees"
                  />
                </table>
                <!-- Expedition -->
                <table v-if="expeditions.length > 0" class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        :colspan="7"
                        class="text-center bg-gray-200 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        Expeditions
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      ></th>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        {{ $t("nb_packages") }}
                      </th>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        {{ $t("transport_mode") }}
                      </th>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        {{ $t("details") }}
                      </th>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        {{ $t("country") }}
                      </th>
                      <th
                        class="text-center bg-gray-100 px-6 py-3 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                      >
                        {{ $t("expedition_fees") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr v-for="(exp, ind) in expeditions" :key="ind">
                      <td
                        class="px-6 py-2 whitespace-no-wrap border-b-2 border-r-2 text-center"
                      >
                        <input
                          type="checkbox"
                          :id="exp._id"
                          @click="getRowDetailExp($event, exp, exp._id)"
                          class="form-checkbox checkbox-exp focus:outline-none focus:shadow-outline"
                        />
                      </td>
                      <td
                        class="px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
                      >
                        <div class="text-sm leading-5 text-blue-900 capitalize">
                          {{ exp.numberOfPackages }}
                        </div>
                      </td>
                      <td
                        class="px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
                      >
                        <div class="text-sm leading-5 text-blue-900 capitalize">
                          {{ exp.transportMode }}
                        </div>
                      </td>
                      <td
                        class="px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
                      >
                        <div class="text-sm leading-5 text-blue-900 capitalize">
                          {{ exp.details.length }} {{ $t("products") }}
                        </div>
                      </td>
                      <td
                        class="px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
                      >
                        <div class="text-sm leading-5 text-blue-900 capitalize">
                          {{ exp.country }}
                        </div>
                      </td>
                      <td
                        class="px-6 py-2 border-b-2 whitespace-no-wrap border-b-2 text-center"
                      >
                        <div
                          class="text-sm leading-5 font-bold text-blue-900 capitalize"
                        >
                          {{ exp.fees }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--order by seller-->
              <div
                class="mt-6"
                style="overflow-x: auto"
                v-if="orders.length == 0"
              >
                <table class="min-w-full w-full">
                  <thead class="relative">
                    <tr
                      class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
                    >
                      <th
                        class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
                      >
                        <span> Seller </span>
                      </th>
                      <th
                        class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
                      >
                        <span> Country </span>
                      </th>
                      <th
                        class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
                      >
                        <span> Number of orders </span>
                      </th>
                      <th
                        class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
                      >
                        <span> Amount to pay </span>
                      </th>
                      <th
                        class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider text-center"
                      >
                        <span> Action </span>
                      </th>
                    </tr>
                  </thead>
                  <loading
                    :active.sync="loadingorderbyseller"
                    :is-full-page="fullPage"
                  ></loading>
                  <tableTotalAmount
                    v-for="(totalorders, index) in ordersByseller"
                    :key="index"
                    :sellerSelect="sellerSelect"
                    :country="formData.warehouses.map((el) => el.country)"
                    :loadingShowPay="loadingShowPay"
                    :item="totalorders"
                    :citiesPrices="citiesPrices"
                    :warehouse="warehouse"
                    :idWharhouse="idWharhouse"
                    @getOrdersBySeller="getOrdersBySeller"
                    @OpenClosePopPayMethode="OpenClosePopPayMethode"
                  />
                </table>
                <!-- <div v-if="ordersByseller.length==0" class="bg-blue-100 py-8">
                                <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
                                    No order to pay
                                </span>
                            </div>-->
              </div>
              <!--/order by seller-->
              <div
                v-if="!formData.seller && ordersByseller.length == 0"
                class="relative bg-blue-100 py-8 mt-1"
              >
                <loading
                  :active.sync="loading"
                  :is-full-page="fullPage"
                ></loading>
                <span
                  class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
                >
                  <p
                    class="font-semibold flex text-green-500 whitespace-no-wrap"
                  >
                    <i class="material-icons mx-3">error</i>
                    <span>No order to paid </span>
                  </p>
                </span>
              </div>
              <div
                v-if="
                  formData.seller &&
                  orders.length == 0 &&
                  formData.details.length == 0 &&
                  ordersByseller.length == 0
                "
                class="relative bg-blue-100 py-8 mt-1"
              >
                <loading
                  :active.sync="loading"
                  :is-full-page="fullPage"
                ></loading>
                <span
                  class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
                >
                  <p
                    class="font-semibold flex text-green-500 whitespace-no-wrap"
                  >
                    <i class="material-icons mx-3">error</i>
                    <span>No order to paid for this Seller</span>
                  </p>
                </span>
              </div>
              <!--pagination -->
              <div class="p-1" v-if="paginate.lastpage > 1">
                <div class="flex flex-col items-center my-12">
                  <button
                    v-if="paginate.currentpage < paginate.lastpage"
                    :disabled="paginate.currentpage == paginate.lastpage"
                    @click="loadMore"
                    class="custom-btn btn-16"
                  >
                    Show more data
                  </button>
                </div>
              </div>
              <!--end pagination -->
              <!--end  Order selectioner -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-modal-2
      name="ModalPay"
      :headerOptions="{ title: $t('payement_methode') }"
      :noFooter="true"
      modalSize="lg"
    >
      <vue-ins-progress-bar></vue-ins-progress-bar>
      <loading :active.sync="loadingPay" :is-full-page="fullPage"></loading>
      <div class="bg-white rounded-lg">
        <div class="w-96 rounded-lg flex">
          <div class="w-1/3 pt-6 flex justify-center items-center">
            <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"
              ><i class="material-icons" style="font-size: 40px"
                >payment</i
              ></span
            >
          </div>
          <div class="w-full pr-4">
            <h3 class="font-bold text-green-600 mb-10">
              {{ $t("payement_methode") }}
            </h3>
            <!-- <div class="flex flex-wrap">  
                    <div class="w-full lg:w-1/2 px-4">
                        <div class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                <p>
                                    <label for="tva" class="bg-white text-gray-700 px-1">{{$t('tva')}} (%)</label>
                                </p>
                            </div> 
                            <p>
                                <v-select @input="setTva" v-model="tvaPayement" :options="tva" ></v-select> 
                            </p>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 px-4">
                            <div class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="transfert_fees" class="bg-white text-gray-700 px-1">{{$t('transfert_fees')}}</label>
                                    </p>
                                </div> 
                                <p>  
                                      <input id="transfert_fees" @input="setTranferfees" v-model="transfertFees"  type="number" min="0"  class="py-2 text-xs px-2 outline-none block h-full w-full"/> 
                                </p>
                            </div>
                    </div>
                </div>   -->
            <div class="w-full px-4">
              <div
                class="mb-5 border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
              >
                <div
                  class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                >
                  <p>
                    <label for="country" class="bg-white text-gray-700 px-1">{{
                      $t("payement_methode")
                    }}</label>
                  </p>
                </div>
                <p>
                  <v-select
                    @input="setSelected"
                    v-model="formData.method"
                    :options="options"
                    label="name"
                  >
                    <template slot="option" slot-scope="option">
                      <img class="flag-img" :src="getImagePay(option.name)" />
                      {{ option.name }}
                    </template>
                  </v-select>
                </p>
              </div>
            </div>
            <table
              class="invoice-table table table-borderless table-nowrap mb-0 w-full"
            >
              <thead class="align-middle">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-top border-top-dashed mt-2">
                  <td class="w-1/6" colspan="3"></td>
                  <td colspan="2" class="p-0">
                    <table
                      class="table table-borderless table-sm table-nowrap align-middle mb-0 w-full"
                    >
                      <tbody>
                        <tr>
                          <th scope="row">Seller</th>
                          <td style="width: 150px">
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              {{
                                formData.seller ? formData.seller.fullName : ""
                              }}
                            </div>
                          </td>
                        </tr>
                        <tr v-if="intervalDates.from_Date && filters.from_Date">
                          <th scope="row">Période Date</th>
                          <td style="width: 150px">
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              [{{ filters.from_Date }}]
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Warehouses</th>
                          <td style="width: 150px">
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              <div
                                class="flex leading-5 capitalize rounded-full"
                              >
                                <span
                                  v-for="(images, r) in formData.warehouses.map(
                                    (el) => el.flag
                                  )"
                                  :key="r"
                                >
                                  <img
                                    :src="images"
                                    class="w-4 h-4 rounded-full mr-2"
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">
                            Sub Total
                            <small
                              >({{ formData.details.length }} orders)</small
                            >
                          </th>
                          <td style="width: 150px">
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              <span
                                v-for="(
                                  price, j
                                ) in formData.amountCurrencySame"
                                :key="j"
                              >
                                {{ price.totalPrice.toFixed(2) }}
                                <sup>{{ price.currency }}</sup>
                                <span
                                  v-if="
                                    j != formData.amountCurrencySame.length - 1
                                  "
                                  >|</span
                                >
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Total Fees</th>
                          <td>
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              <span
                                v-for="(
                                  price, j
                                ) in formData.amountCurrencySame"
                                :key="j"
                              >
                                {{ price.fees.toFixed(2) }}
                                <sup>{{ price.currency }}</sup>
                                <span
                                  v-if="
                                    j != formData.amountCurrencySame.length - 1
                                  "
                                  >|</span
                                >
                              </span>
                            </div>
                          </td>
                        </tr>

                        <tr class="border-top border-top-dashed">
                          <th scope="row">Total Amount</th>
                          <td>
                            <div
                              class="form-control bg-light border-0"
                              readonly=""
                            >
                              <span
                                v-for="(
                                  price, j
                                ) in formData.amountCurrencySame"
                                :key="j"
                              >
                                {{ price.amount.toFixed(2) }}
                                <sup>{{ price.currency }}</sup>
                                <span
                                  v-if="
                                    j != formData.amountCurrencySame.length - 1
                                  "
                                  >|</span
                                >
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!--end table-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="p-4 flex space-x-4">
          <a
            href="#"
            @click="OpenClosePopPayMethode('close')"
            class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm"
            >{{ $t("cancel") }}</a
          >
          <a
            href="#"
            @click="save"
            class="w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm"
            >{{ $t("confirm") }}</a
          >
        </div>
      </div>
    </vue-modal-2>
  </div>
</template>

<script>
import tableBody from "@/views/payments/tableOrders.vue";
import tableTotalAmount from "@/views/payments/tableTotalAmount.vue";
import image1 from "@/assets/pay/cash.png";
import image2 from "@/assets/pay/paypal.png";
import image3 from "@/assets/pay/transfer.png";

export default {
  components: {
    tableBody,
    tableTotalAmount,
  },
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      formData: {
        seller: null,
        date: Date.now(),
        amount: 0,
        details: [],
        expeditions: [],
        warehouses: [],
        method: "cash",
        amountCurrency: [],
        info: {},
        infoCurrency: [],
      },
      intervalDates: {
        from_Date: null,
        to_Date: null,
      },
      loadingReset: false,
      loadingFind: false,
      loadingRefresh: false,
      settings: {
        shipping: { fees: 0 },
        order: { feesConfirmation: 0, fulfillement_fees: 0 },
        quality_control: 0,
      },
      amountCurrencySame: [],
      settingsSeller: {
        shipping: { fees: 0 },
        order: { feesConfirmation: 0, fulfillement_fees: 0 },
        quality_control: 0,
      },
      tva: [],
      settingsFees: {},
      info: { currency: 1 },
      loadingorderbyseller: false,
      ordersByseller: [],
      tvaPayement: "0",
      transfertFees: 0,
      feesVat: 0,
      confirmation_vat: 0,
      shipping_vat: 0,
      shipping_refund_vat: 0,
      shipping_cancel_vat: 0,
      quality_control: 0,
      fulfillement_fees_vat: 0,
      sellers: [],
      filtersLoad: {},
      filters: {},
      seller: null,
      orders: [],
      totalOrders: [],
      detailsAllorders: [],
      expeditions: [],
      shippings: [],
      selectedRows: [],
      fullPage: false,
      loading: false,
      loadingPay: false,
      loadingShowPay: false,
      sellerSelect: null,
      limit: 10,
      shippingType: "normal",
      totalfeesorder: 0,
      //idWharhouse:null,
      dataCountry: this.$countries,
      warehouses: [],
      cuurency: "",
      warehouse: "",
      servicesSeller: null,
      citiesPrices: [],
      priceCity: 0,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      headings: [
        {
          value: "Order & Shipping",
        },
        {
          value: "Quality control",
        },
        {
          value: "Confirmation fees",
        },
        {
          value: "Fulfillement fees",
        },
        {
          value: "Shipping fees",
        },
        {
          value: "Return fees",
        },
        {
          value: "Cancelation fees",
        },
      ],
      options: [
        { name: "transfer", image: "AF" },
        { name: "paypal", image: "AX" },
        { name: "cash", image: "AL" },
      ],
    };
  },
  async mounted() {
    this.getwharhouseId();
    //await this.getCitiesPrices();
    //await this.getUser();
    await this.getSellers();
    await this.getSettingsPricipal();
    this.getSettings();
    await this.getWarehouses();
    await this.getAllOrdersBySeller({});
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //    this.filters.seller=this.formData.seller=null;this.orders=[];
      //    this.intervalDates.from_Date=this.intervalDates.to_Date=this.filters.from_Date=this.filters.to_Date=null;
      //   if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      //   this.$f.objectToQueryString({'country':await this.warhouseSelected})
      //   this.filters.country=await this.warhouseSelected;
      // //  await this.getwharhouseId();
      //   //await this.getCitiesPrices();
      //   await this.getSellers();
      //   this.getSettings();
      //   await this.getOrdersBySeller(this.formData.seller,'seller');
      //   await this.getAllOrdersBySeller({changeWarehouse:true});
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      this.getwharhouseId();
      this.filters.seller = this.formData.seller = null;
      this.orders = [];
      this.intervalDates.from_Date =
        this.intervalDates.to_Date =
        this.filters.from_Date =
        this.filters.to_Date =
          null;
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.$f.objectToQueryString({ country: this.idWharhouse.country });
      this.filters.country = this.idWharhouse.country;
      await this.getSellers();
      this.getSettings();
      await this.getOrdersBySeller(this.formData.seller, "seller");
      //  await this.getAllOrdersBySeller({changeWarehouse:true});
    },
  },
  methods: {
    async getWarehouses() {
      const filter = { limit: 1000 };
      let wares = [];
      const res = await this.$server.search("warehouses", filter);
      if (res && res.content.results) {
        wares = res.content.results;
      } else wares = [];

      for (let i in wares) {
        for (let j in this.currentUser.countries) {
          if (
            !this.warehouses.find((o) => o.country === wares[i].country) &&
            (wares[i].country === this.currentUser.countries[j].code ||
              this.currentUser.countries[j])
          ) {
            this.warehouses.push(wares[i]);
          }
        }
      }
    },
    async getCitiesPrices() {
      const resCities2 = await this.$server.get("zones", {
        allCitiesAndFees: true,
        country: await this.warhouseSelected,
      });
      if (resCities2.content.length > 0) {
        for (let i in resCities2.content) {
          for (let j in resCities2.content[i]._id.cities) {
            this.citiesPrices.push({
              name: resCities2.content[i]._id.cities[j],
              price: resCities2.content[i]._id.price,
            });
          }
        }
      } else this.citiesPrices = [];
    },
    async getCurrency() {
      this.warehouse = await this.warhouseSelected;
      await this.$store.dispatch("wharhouse/getCurrency").then((res) => {
        this.cuurency = res;
      });
    },
    getwharhouseId() {
      this.formData.warehouses = [];
      this.formData.warehouses.push(this.idWharhouse);
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    async getAllOrdersBySeller(filters) {
      if (this.$f.QueryStringToobject()) {
        this.filters = this.$f.QueryStringToobject();
        if (this.filters.from_Date)
          this.intervalDates = {
            from_Date: this.filters.from_Date,
            to_Date: this.filters.to_Date,
          };
        else
          this.$f.objectToQueryString(
            _.pick(this.filters, ["country", "seller"])
          );
      } else this.$f.objectToQueryString({ country: this.idWharhouse.country });

      this.filters = this.$f.QueryStringToobject();
      if (
        this.intervalDates.from_Date &&
        !Array.isArray(this.intervalDates.from_Date)
      ) {
        this.intervalDates.from_Date = this.intervalDates.from_Date
          .split(",")
          .map((el) => new Date(new Date(el).toString()));
      }
      this.filters.idWharhouse = this.idWharhouse._id;

      if (!this.filters.country)
        this.filters.country = this.idWharhouse.country;
      else {
        if (!filters.changeWarehouse) {
          this.filters.country.split("-").forEach(
            function (country) {
              var index = this.formData.warehouses.findIndex(
                (x) => x.country == country
              );
              if (index === -1) {
                const obj = this.warehouses.find((o) => o.country === country);
                this.formData.warehouses.push(obj);
              }
            }.bind(this)
          );
        }
      }

      this.filters.settings = this.settings;
      this.filters.warehouses = this.warehouses;

      if (this.filters.seller)
        await this.getOrdersBySeller(this.filters.seller, "seller");
      else {
        this.loadingorderbyseller = true;
        this.ordersByseller = [];
        const res = await this.$server.getOrdersByseller(
          "orders",
          this.filters
        );
        this.loadingorderbyseller = false;
        if (res && Array.isArray(res)) {
          this.orders = [];
          this.ordersByseller = res;
        } else {
          alert(res, "warning");
          this.ordersByseller = [];
        }
      }
    },
    async getSettingsPricipal() {
      const data = await this.$server.get("settings");
      this.settingsFees = data.content;
    },
    getSettings(seller = null, warehouse = null) {
      const currentSettingW = this.getElementInArray(
        this.settingsFees.details,
        warehouse ? warehouse._id : this.idWharhouse._id
      );

      if (currentSettingW) this.settings = currentSettingW;
      else
        this.settings = {
          shipping: { fees: 0 },
          order: { feesConfirmation: 0, fulfillement_fees: 0 },
          quality_control: 0,
          info: { mad: 1, currency: 1 },
        };
      this.tva = this.settingsFees.tva;
      this.info.currency = this.settings.info.currency;

      if (seller) this.seller = seller;

      if (this.seller) {
        const currentSettingSeller = this.getElementInArray(
          this.seller.fees,
          warehouse ? warehouse._id : this.idWharhouse._id
        );
        //fees confirmation
        if (
          (currentSettingSeller && currentSettingSeller.confirmation == -1) ||
          !currentSettingSeller
        )
          this.confirmation_vat = this.settings.order.feesConfirmation;
        else this.confirmation_vat = currentSettingSeller.confirmation;
        //fees fulfillement
        if (
          (currentSettingSeller &&
            currentSettingSeller.fulfillement_fees == -1) ||
          !currentSettingSeller
        )
          this.fulfillement_fees_vat = this.settings.order.fulfillement_fees;
        else
          this.fulfillement_fees_vat = currentSettingSeller.fulfillement_fees;

        //fees shipping
        if (
          (currentSettingSeller && currentSettingSeller.shipping == -1) ||
          !currentSettingSeller
        )
          this.shipping_vat = this.settings.shipping.fees;
        else this.shipping_vat = currentSettingSeller.shipping;

        //Cancelation shipping
        if (
          (currentSettingSeller && currentSettingSeller.cancelation == -1) ||
          !currentSettingSeller
        )
          this.shipping_cancel_vat = this.settings.shipping.cancelation;
        else this.shipping_cancel_vat = currentSettingSeller.cancelation;

        //refund shipping
        if (
          (currentSettingSeller && currentSettingSeller.refund == -1) ||
          !currentSettingSeller
        )
          this.shipping_refund_vat = this.settings.shipping.refund;
        else this.shipping_refund_vat = currentSettingSeller.refund;

        //qualite controle
        if (
          (currentSettingSeller &&
            currentSettingSeller.quality_control == -1) ||
          !currentSettingSeller
        )
          this.quality_control = this.settings.quality_control;
        else this.quality_control = currentSettingSeller.quality_control;

        //check seller service
        const services = this.getElementInArray(
          this.seller.settings,
          warehouse ? warehouse._id : this.idWharhouse._id
        );
        if (services && !services.confirmation) this.confirmation_vat = 0;
        if (services && !services.fulfillement) this.fulfillement_fees_vat = 0;
        if (services && !services.shipping) {
          this.shipping_vat =
            this.shipping_cancel_vat =
            this.shipping_refund_vat =
              0;
        }
      }
      // return {confirmation_vat:this.confirmation_vat,fulfillement_fees_vat:this.fulfillement_fees_vat,shipping_vat:this.shipping_vat,shipping_cancel_vat:this.shipping_cancel_vat,shipping_refund_vat:this.shipping_refund_vat,quality_control:this.quality_control}
    },
    getImagePay(name) {
      if (name == "cash") return image1;
      else if (name == "paypal") return image2;
      else return image3;
    },
    setSelected(method) {
      if (method) {
        this.formData.method = method.name;
      } else {
        this.formData.method = "cash";
      }
    },
    setTva(tva) {
      let tvafees = 0;
      if (!this.transfertFees) this.transfertFees = 0;
      if (parseFloat(this.transfertFees) != 0)
        tvafees += parseFloat(this.transfertFees);

      if (tva) {
        if (parseInt(tva) > 0) {
          this.TotalAmountOrders();
          tvafees += (this.formData.amount * parseInt(tva)) / 100;
          this.TotalAmountOrders(tvafees);
        }
      } else {
        this.TotalAmountOrders(tvafees);
        this.tvaPayement = "0";
      }
      this.feesVat = parseFloat(tvafees);
    },
    setTranferfees(event) {
      if (!this.transfertFees) this.transfertFees = 0;
      let fees = parseFloat(this.transfertFees);
      this.TotalAmountOrders();

      if (parseInt(this.tvaPayement) != 0)
        fees += (this.formData.amount * parseInt(this.tvaPayement)) / 100;
      this.TotalAmountOrders(fees);
    },
    async getOrdersBySeller(seller, type) {
      this.orders = [];
      this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      if (seller) {
        if (type == "seller") {
          this.loading = true;
          await this.getSeller(seller._id || seller);
          this.formData.seller = this.seller;
          this.filters.seller = this.seller._id;
          this.ordersByseller = [];
          const filters = {};
          const FilterLink = this.$f.QueryStringToobject();
          if (this.intervalDates.from_Date && FilterLink.from_Date) {
            filters.from_Date = FilterLink.from_Date;
            filters.to_Date = this.filters.to_Date;
          }
          this.$f.objectToQueryString(
            _.pick(this.filters, ["country", "seller", "from_Date"])
          );

          if (this.filters.country) filters.country = this.filters.country;
          this.sellerSelect = this.seller._id.toString();
          this.loadingShowPay = true;
          this.getSettings();
          await this.getTotalOrders();

          await this.getOrders(filters);
          if (this.formData.warehouses.length == 1)
            await this.getExpedition(filters);
          this.getSettings();
          this.loading = false;
          this.loadingShowPay = false;
        } else {
          if (this.formData.warehouses.length == 0)
            this.formData.warehouses.push(this.idWharhouse);
          this.filters.country = this.formData.warehouses
            .map((e) => e.country)
            .join("-");
          this.$f.objectToQueryString(
            _.pick(this.filters, ["country", "seller", "from_Date"])
          );

          this.orders = this.ordersByseller = [];
          await this.getAllOrdersBySeller({});
        }
      } else {
        if (type == "seller") {
          this.sellerSelect =
            this.filters.seller =
            this.formData.seller =
            this.seller =
              null;
          this.$f.objectToQueryString(
            _.pick(this.filters, ["country", "from_Date"])
          );
          await this.getAllOrdersBySeller({});
        }
      }
    },
    async refresh(type = null) {
      // const filters = {};
      // await this.getOrders(filters);
      // if(this.formData.warehouses.length==1) await this.getExpedition(filters);
      // //await this.getShippings(filters);
      // this.getSettings();
      // await this.getTotalOrders();

      if (type) {
        this.sellerSelect =
          this.filters.seller =
          this.formData.seller =
          this.seller =
            null;
      }
      this.$f.objectToQueryString(
        _.pick(this.filters, ["country", "from_Date"])
      );
      await this.getAllOrdersBySeller({});
    },
    async getStatusByName(name) {
      const result = await this.$server.get("status", { name: name });
      return result.content._id;
    },
    async getOrders(filter) {
      //const statusId = await this.getStatusByName("Paid");
      //const statusIdR = await this.getStatusByName("Return");
      const FilterLink = this.$f.QueryStringToobject();
      if (this.intervalDates.from_Date && FilterLink.from_Date) {
        filter.from_Date = FilterLink.from_Date;
        filter.to_Date = this.intervalDates.to_Date;
      }
      filter.seller = this.formData.seller._id;
      filter.limit = this.limit;
      if (!this.filters.country)
        filter["country"] = await this.warhouseSelected;
      filter.status = "PaidOrRefunded";
      filter.page = this.paginate.currentpage++;
      //  this.servicesSeller= this.getElementInArray(this.formData.seller.settings,this.idWharhouse._id);
      this.headings = [
        {
          value: "Order & Shipping",
        },
        {
          value: "Quality control",
        },
        {
          value: "Confirmation fees",
        },
        {
          value: "Fulfillement fees",
        },
        {
          value: "Shipping fees",
        },
        {
          value: "Return fees",
        },
        {
          value: "Cancelation fees",
        },
      ];
      //   if(this.servicesSeller && !this.servicesSeller.confirmation) this.headings.splice(this.headings.findIndex(item => item.value === "Confirmation fees"), 1)
      //   if(this.servicesSeller && !this.servicesSeller.fulfillement)  this.headings.splice(this.headings.findIndex(item => item.value === "Fulfillement fees"), 1)
      //   if(this.servicesSeller && !this.servicesSeller.shipping){

      //      this.headings.splice(this.headings.findIndex(item => item.value === "Shipping fees"), 1)
      //      this.headings.splice(this.headings.findIndex(item => item.value === "Return fees"), 1)
      //      this.headings.splice(this.headings.findIndex(item => item.value === "Cancelation fees"), 1)
      //   }

      const res = await this.$server.search(
        "shippings",
        _.pick(filter, [
          "country",
          "seller",
          "status",
          "page",
          "limit",
          "from_Date",
        ])
      );
      if (res && res.content.results && !res.content.error) {
        for (let i in res.content.results) {
          this.orders.push(res.content.results[i]);
        }
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        // this.orders = this.orders.sort((a, b) => new Date(b.date) - new Date(a.date));
        this.orders = this.orders.sort((a, b) => b.country - a.country);
        this.orders.forEach((e) => {
          if (e.status == "return") e.order.totalPrice = 0;
        });
      } else {
        this.orders = [];
        this.paginate.lastpage = 1;
        if (res && res.content.error) alert(res.content.error, "warning");
      }
    },
    totalorderFees(
      warehouse,
      order,
      data,
      conf_fees,
      fulfillement_fees,
      shipping_fees,
      shipping_refund_fees,
      shipping_cancel_fees,
      quality_control_fees
    ) {
      let totalfees = 0;
      let returnfees = 0;
      let cancell_fess = 0;
      const services = this.getElementInArray(
        order.seller.settings,
        warehouse._id
      );
      if (order.status == "return") shipping_fees = 0;
      if (
        order.status == "return" &&
        order.isRefunded &&
        services &&
        services.shipping
      )
        returnfees = shipping_refund_fees;
      if (
        order.status == "return" &&
        order.isCancelled &&
        services &&
        services.shipping
      )
        cancell_fess = shipping_cancel_fees;
      totalfees +=
        conf_fees +
        fulfillement_fees +
        shipping_fees +
        returnfees +
        cancell_fess +
        quality_control_fees;
      data.push({
        currency: warehouse.currency,
        country: order.country,
        totalPrice: order.order.totalPrice,
        orderObjet: order.orderObjet,
        order: order.order._id,
        shipping: order._id,
        amount: order.order.totalPrice - totalfees,
        confirmation_vat: conf_fees,
        fulfillement_fees_vat: fulfillement_fees,
        shipping_vat: shipping_fees,
        shipping_refund_vat: returnfees,
        shipping_cancel_vat: cancell_fess,
        quality_control: quality_control_fees,
      });
    },
    getPriceBycity(city, array) {
      let price = 0;
      for (var i = 0; i < array.length; i++) {
        if (array[i].name.toLowerCase() === city.toLowerCase()) {
          price = array[i].price;
          break;
        }
      }
      return price;
    },
    async getTotalOrders(seller = null) {
      let filter = {};
      if (seller) this.getSettings(seller);
      filter.seller = seller ? seller._id : this.formData.seller._id;
      filter.limit = 10000;
      filter["country"] = await this.warhouseSelected;
      if (this.filters.country) filter["country"] = this.filters.country;
      const FilterLink = this.$f.QueryStringToobject();
      if (this.intervalDates.from_Date && FilterLink.from_Date) {
        filter.from_Date = FilterLink.from_Date;
        filter.to_Date = this.intervalDates.to_Date;
      }
      filter.status = "PaidOrRefunded";
      this.$insProgress.start();

      const res = await this.$server.search("shippings", filter);
      this.detailsAllorders = [];
      this.formData.details = [];
      if (res && res.content.results && !res.content.error) {
        this.totalOrders = res.content.results;
        for (let i in this.totalOrders) {
          const dataWarehouse = this.warehouses.find(
            (item) => item.country === this.totalOrders[i].country
          );
          this.getSettings(
            this.totalOrders[i].seller,
            dataWarehouse ? dataWarehouse : this.idWharhouse
          );
          if (this.totalOrders[i].status == "return") {
            this.totalOrders[i].order.totalPrice = 0;
          }
          const services = this.getElementInArray(
            this.totalOrders[i].seller.settings,
            this.idWharhouse._id
          );
          if (
            this.totalOrders[i].country == "MA" &&
            services &&
            services.shipping &&
            this.totalOrders[i].order.customer.city
          ) {
            this.shipping_vat = this.getPriceBycity(
              this.totalOrders[i].order.customer.city,
              this.citiesPrices
            );
          }
          this.totalorderFees(
            dataWarehouse ? dataWarehouse : this.idWharhouse,
            this.totalOrders[i],
            this.detailsAllorders,
            this.confirmation_vat,
            this.fulfillement_fees_vat,
            this.shipping_vat,
            this.shipping_refund_vat,
            this.shipping_cancel_vat,
            this.quality_control
          );
        }
      } else {
        this.totalOrders = [];
        this.loadingShowPay = false;
        if (res && res.content.error) alert(res.content.error, "warning");
      }
      this.$insProgress.finish();
    },
    totalOrdersAmountFees() {
      let amount = 0;
      let fees = 0;
      let amountArray = [];
      let feesArray = [];

      let groupByCurrency = _.groupBy(this.detailsAllorders, function (n) {
        return n.country;
      });
      groupByCurrency = Object.entries(groupByCurrency).map((obj) => ({
        currency: this.warehouses.find((item) => item.country === obj[0])
          .currency,
        country: obj[0],
        data: obj[1],
        count: obj[1].length,
        totalPrice: obj[1]
          .map((item) => item.totalPrice)
          .reduce((prev, next) => prev + next),
        amount: obj[1]
          .map((item) => item.amount)
          .reduce((prev, next) => prev + next),
        fees: obj[1]
          .map(
            (item) =>
              item.confirmation_vat +
              item.fulfillement_fees_vat +
              item.shipping_cancel_vat +
              item.shipping_refund_vat +
              item.shipping_vat +
              item.quality_control
          )
          .reduce((prev, next) => prev + next),
      }));

      //     for (let p = 0; p < this.detailsAllorders.length; p++) {
      //         amount+=this.detailsAllorders[p].amount;
      //         fees+=this.detailsAllorders[p].confirmation_vat+this.detailsAllorders[p].fulfillement_fees_vat+this.detailsAllorders[p].shipping_cancel_vat+this.detailsAllorders[p].shipping_refund_vat+this.detailsAllorders[p].shipping_vat+this.detailsAllorders[p].quality_control;
      //     }
      return {
        dataArray: groupByCurrency,
        amount: amount,
        fees: fees,
        count: this.detailsAllorders.length,
      };
    },
    totalfeesorderF(item) {
      this.totalfeesorder =
        item.confirmation_vat +
        item.fulfillement_fees_vat +
        item.shipping_vat +
        item.shipping_refund_vat +
        item.shipping_cancel_vat +
        item.quality_control;
    },
    async getShippingsType(id) {
      const filters = {};
      filters.order = id;
      filters.status = "paid";
      const res = await this.$server.search("shippings", filters);
      if (res.content.results) {
        this.shippingType = res.content.results[0].type;
      }
      return this.shippingType;
    },
    async getExpedition(filterExp) {
      if (!filterExp.country)
        filterExp["country"] = await this.warhouseSelected;
      filterExp.seller = this.formData.seller._id;
      filterExp.status = "received";
      filterExp.limit = 10;
      filterExp.paid = false;
      const res = await this.$server.search(
        "expeditions",
        _.pick(filterExp, ["paid", "limit", "status", "seller", "country"])
      );
      if (res.content.results) {
        this.expeditions = res.content.results;
      } else this.expeditions = [];
    },
    async getShippings(filter) {
      const res = await this.$server.search("shippings", filter);
      if (res.content.results) {
        this.shippings = res.content.results;
      } else this.shippings = [];
    },
    selectAllCheckbox($event) {
      let columns = document.querySelectorAll(".rowCheckbox");

      this.formData.details = [];

      if ($event.target.checked == true) {
        columns.forEach((column) => {
          column.checked = true;

          let order = this.orders.filter(
            (item) => item.order._id.toString() === column.id.toString()
          );
          const dataWarehouse = this.warehouses.find(
            (j) => j.country === order[0].country
          );
          const data = {
            order: column.id,
            country: order[0].country,
            currency: dataWarehouse
              ? dataWarehouse.currency
              : this.idWharhouse.currency,
            totalPrice: order[0].order.totalPrice,
            shipping: order[0]._id,
            orderObjet: order[0].orderObjet,
            confirmation_vat: parseInt(order[0].order.confirmation_vat),
            fulfillement_fees_vat: parseInt(
              order[0].order.fulfillement_fees_vat
            ),
            shipping_vat: parseInt(order[0].order.shipping_vat),
            shipping_refund_vat: parseInt(order[0].order.shipping_refund_vat),
            shipping_cancel_vat: parseInt(order[0].order.shipping_cancel_vat),
            quality_control: parseInt(order[0].order.quality_control),
            amount: this.TotalAmont(
              order[0].order.totalPrice,
              order[0].order.confirmation_vat,
              order[0].order.fulfillement_fees_vat,
              order[0].order.shipping_vat,
              order[0].order.shipping_refund_vat,
              order[0].order.shipping_cancel_vat,
              order[0].order.quality_control
            ),
          };
          this.formData.details.push(data);
        });
      } else {
        columns.forEach((column) => {
          column.checked = false;
        });
        let columnsExp = document.querySelectorAll(".checkbox-exp");
        columnsExp.forEach((column) => {
          column.checked = false;
        });
        this.formData.details = [];
        this.formData.expeditions = [];
      }
      this.TotalAmountOrders();
    },
    TotalAmont: function (
      total,
      conf_fees,
      ful_fees,
      shipp_fees,
      refound_fees,
      chang_fees,
      quality_control
    ) {
      return (
        parseFloat(total) -
        (parseFloat(conf_fees) +
          parseFloat(ful_fees) +
          parseFloat(shipp_fees) +
          parseFloat(refound_fees) +
          parseFloat(chang_fees) +
          parseFloat(quality_control))
      );
    },
    sumObjAmountCurrency(objArr) {
      var newArr = [];
      // loop over the objects in the array
      objArr.forEach(function (obj, ind, arr) {
        if (ind === arr.length - 1 || obj.currency !== arr[ind + 1].currency) {
          newArr.push(obj);
        } else {
          arr[ind + 1].amount += obj.amount1 || obj.amount;
          arr[ind + 1].count += obj.count1 || obj.count;
          arr[ind + 1].fees += obj.fees1 || obj.fees;
          arr[ind + 1].totalPrice += obj.totalPrice1 || obj.totalPrice;
        }
      });
      // return the result
      return newArr;
    },
    TotalAmountOrders(tva_tarnsFees = null) {
      this.formData.amount = 0;
      this.formData.amountCurrency = [];
      this.amountCurrencySame = [];

      let groupByCurrency = _.groupBy(this.formData.details, function (n) {
        return n.country;
      });

      groupByCurrency = Object.entries(groupByCurrency).map((obj) => ({
        count1: obj[1].length,
        currency1: this.warehouses.find((item) => item.country === obj[0])
          .currency,
        country1: obj[0],
        data1: obj[1],
        totalPrice1: obj[1]
          .map((item) => item.totalPrice)
          .reduce((prev, next) => prev + next),
        amount1: obj[1]
          .map((item) => item.amount)
          .reduce((prev, next) => prev + next),
        fees1: obj[1]
          .map(
            (item) =>
              item.confirmation_vat +
              item.fulfillement_fees_vat +
              item.shipping_cancel_vat +
              item.shipping_refund_vat +
              item.shipping_vat +
              item.quality_control
          )
          .reduce((prev, next) => prev + next),
      }));
      //
      let newArray = [];
      groupByCurrency.forEach(function (ele) {
        ele = JSON.stringify(ele); // parse
        ele = JSON.parse(ele); //
        ele.count = ele.count1;
        ele.currency = ele.currency1;
        ele.country = ele.country1;
        ele.data = ele.data1;
        ele.totalPrice = ele.totalPrice1;
        ele.amount = ele.amount1;
        ele.fees = ele.fees1;
        newArray.push(ele);
      });

      this.formData.amountCurrency = newArray;

      if (this.formData.amountCurrency.length > 0)
        this.formData.amount += this.formData.amountCurrency
          .map((item) => item.amount1)
          .reduce((prev, next) => prev + next);
      // for (let i in this.formData.details) {
      //     this.formData.amount += this.formData.details[i].amount;
      // }
      for (let i in this.formData.expeditions) {
        this.formData.amount -= this.formData.expeditions[i].fees;
      }

      if (parseFloat(tva_tarnsFees) > 0) this.formData.amount -= tva_tarnsFees;
      this.amountCurrencySame = this.sumObjAmountCurrency(
        this.formData.amountCurrency
      );
      this.formData.amountCurrencySame = this.amountCurrencySame;
    },
    TotalFees() {
      var confirmation_vat = 0;
      var fulfillement_fees_vat = 0;
      var shipping_vat = 0;
      var shipping_refund_vat = 0;
      var shipping_cancel_vat = 0;
      var quality_control = 0;
      for (let i in this.formData.details) {
        confirmation_vat += this.formData.details[i].confirmation_vat;
        fulfillement_fees_vat += this.formData.details[i].fulfillement_fees_vat;
        shipping_vat += this.formData.details[i].shipping_vat;
        shipping_refund_vat += this.formData.details[i].shipping_refund_vat;
        shipping_cancel_vat += this.formData.details[i].shipping_cancel_vat;
        quality_control += this.formData.details[i].quality_control;
      }
      return (
        confirmation_vat +
        fulfillement_fees_vat +
        shipping_vat +
        shipping_refund_vat +
        shipping_cancel_vat +
        quality_control
      );
    },
    UpdateRowFees(id, data1, data2, data3, data4, data5, data6, amount) {
      this.formData.details.filter((item) => {
        if (item.order === id) {
          item.confirmation_vat = parseInt(data1);
          item.fulfillement_fees_vat = parseInt(data2);
          item.shipping_vat = parseInt(data3);
          item.shipping_refund_vat = parseInt(data4);
          item.shipping_cancel_vat = parseInt(data5);
          item.quality_control = parseInt(data6);
          item.amount = amount;
        }
      });
      this.TotalAmountOrders();
    },
    getRowDetailExp($event, itemExp, id) {
      let itemInRow = this.formData.expeditions.filter(
        (item) => item.id === id
      );
      let isItemInRow = itemInRow.length > 0;
      if (isItemInRow) {
        let pos = this.formData.expeditions
          .map(function (e) {
            return e.id;
          })
          .indexOf(id);
        this.formData.expeditions.splice(pos, 1);
      } else {
        const data = {
          id: id,
          fees: parseInt(itemExp.fees),
        };
        this.formData.expeditions.push(data);
      }
      this.TotalAmountOrders();
    },
    getRowDetail($event, item, id) {
      // let rows = this.selectedRows;

      let itemInRow = this.formData.details.filter((item) => item.order === id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.formData.details
          .map(function (e) {
            return e.order;
          })
          .indexOf(id);
        this.formData.details.splice(pos, 1);
      } else {
        const dataWarehouse = this.warehouses.find(
          (j) => j.country === item.country
        );
        const data = {
          order: id,
          country: item.country,
          currency: dataWarehouse
            ? dataWarehouse.currency
            : this.idWharhouse.currency,
          totalPrice: item.order.totalPrice,
          shipping: item._id,
          orderObjet: item.orderObjet,
          confirmation_vat: parseInt(item.order.confirmation_vat),
          fulfillement_fees_vat: parseInt(item.order.fulfillement_fees_vat),
          shipping_vat: parseInt(item.order.shipping_vat),
          shipping_refund_vat: parseInt(item.order.shipping_refund_vat),
          shipping_cancel_vat: parseInt(item.order.shipping_cancel_vat),
          quality_control: parseInt(item.order.quality_control),
          amount: this.TotalAmont(
            item.order.totalPrice,
            item.order.confirmation_vat,
            item.order.fulfillement_fees_vat,
            item.order.shipping_vat,
            item.order.shipping_refund_vat,
            item.order.shipping_cancel_vat,
            item.order.quality_control
          ),
        };

        this.formData.details.push(data);
      }
      if (this.formData.details.length === 0) {
        let columns = document.querySelectorAll(".checkallbbox");
        columns.forEach((column) => {
          column.checked = false;
        });
        let columnsExp = document.querySelectorAll(".checkbox-exp");
        columnsExp.forEach((column) => {
          column.checked = false;
        });
        this.formData.expeditions = [];
      }
      this.TotalAmountOrders();
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: this.limit,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    searchSellers: _.debounce(async function (search) {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: this.limit,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    }, 500),
    async getSeller(id) {
      const filter = {
        _id: id,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.seller = res.content.results[0];
      else this.seller = null;
    },
    async save() {
      let modal_id = "ModalPay";
      if (this.formData.details.length > 0) {
        if (Math.sign(this.formData.amount) == -1) {
          alert(this.$t("pay_negative"), "error");
        } else {
          this.loadingPay = true;
          this.$insProgress.start();
          //this.formData.vat=parseInt(this.tvaPayement);
          //this.formData.vatFees=parseInt(this.feesVat);
          //this.formData.transfert_fees=parseFloat(this.transfertFees);
          this.formData.country = await this.warhouseSelected;
          this.formData.count = this.formData.details.length;
          this.formData.info.currency = this.info.currency;
          this.formData.info.confirmation_vat = this.confirmation_vat;
          this.formData.info.shipping_vat = this.shipping_vat;

          this.formData.warehouses.forEach((war) => {
            if (
              !this.formData.infoCurrency.find((o) => o.country === war.country)
            ) {
              let info = { mad: 1, currency: 1 };
              const currentSettingW = this.getElementInArray(
                this.settingsFees.details,
                war._id
              );
              if (currentSettingW) info = currentSettingW.info;
              this.formData.infoCurrency.push({
                currency: info.currency,
                country: war.country,
                currencyName: war.currency,
                confirmation_vat: 0,
                shipping_vat: 0,
              });
            }
          });
          this.formData.infoCurrency.forEach((info) => {
            const dataCountry = this.formData.details.find(
              (j) => j.country === info.country
            );
            if (dataCountry) {
              info.confirmation_vat = dataCountry.confirmation_vat;
              info.shipping_vat = dataCountry.shipping_vat;
            }
          });
          const FilterLink = this.$f.QueryStringToobject();
          if (this.intervalDates.from_Date && FilterLink.from_Date) {
            if (FilterLink.from_Date.split(",").length != 2) {
              alert("Error Range date, please actulisé la page", "error");
              this.loadingPay = false;
              this.$insProgress.finish();
            }
            this.formData.intervalDate = {
              fromDate: new Date(FilterLink.from_Date.split(",")[0]),
              toDate: new Date(FilterLink.from_Date.split(",")[1]),
            };
          }

          // return false
          const data = await this.$server.create("payments", this.formData);
          this.loadingPay = false;

          this.$insProgress.finish();
          if (data && data._id) {
            this.loadingPay = false;
            this.formData.details = [];
            this.formData.expeditions = [];
            this.formData.infoCurrency = [];
            this.shippings = [];
            this.$vm2.close(modal_id);
            await this.refresh("payement_created");
            // this.$router.push('/payments')
            alert(this.$t("payement_created"), "success");
          } else
            alert(
              data.content ? data.content.error || data.content : data,
              "warning"
            );
        }
      } else {
        alert(this.$t("no_order_selected"), "warning");
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },

    async OpenClosePopPayMethode(action, type = null, seller = null) {
      let modal_id = "ModalPay";
      if (type === "allOrders") this.loadingShowPay = true;

      if (seller) {
        this.sellerSelect = seller._id.toString();
        await this.getTotalOrders(seller);
      } else this.sellerSelect = null;

      if (type === "allOrders") {
        this.formData.details = [];
        this.formData.amount = 0;
        this.formData.details = this.detailsAllorders;
        if (seller) this.formData.seller = seller;
        this.formData.amount = this.totalOrdersAmountFees()
          .dataArray.map((item) => item.amount)
          .reduce((prev, next) => prev + next);
        this.formData.amountCurrency = this.totalOrdersAmountFees().dataArray;
        this.formData.amountCurrencySame = this.sumObjAmountCurrency(
          this.totalOrdersAmountFees().dataArray
        );

        this.loadingShowPay = false;
      }
      if (action === "open") {
        if (this.formData.details.length > 0) {
          if (
            Math.sign(this.formData.amount) == -1 ||
            Math.sign(this.formData.amount) == 0
          ) {
            alert(this.$t("pay_negative"), "error");
          } else {
            this.$vm2.open(modal_id);
          }
        } else alert(this.$t("no_order_selected"), "warning");
      } else {
        this.formData.details = [];
        this.formData.amount = 0;
        this.$vm2.close(modal_id);
      }
    },
    async loadMore() {
      var page = 0;
      page = ++this.paginate.currentpage;
      this.filters.page = page;
      this.filters.limit = this.limit;
      this.getOrders(this.filters);
    },
    async FindOrderByBetweenDate() {
      this.loadingFind = true;
      const filters = {};
      this.paginate.currentpage = 1;
      // await this.getOrders(this.filters);
      // await this.getTotalOrders();

      this.orders = this.ordersByseller = [];
      this.filters.seller = this.formData.seller = this.filters.seller = null;
      if (this.intervalDates.from_Date) {
        this.filters.from_Date = this.intervalDates.from_Date.map((el) =>
          this.$moment(el).format("yyyy-MM-DD")
        );
        this.filters.to_Date = this.intervalDates.to_Date;
      } else {
        alert("Please select date !!!", "warning");
        this.loadingFind = false;
        return false;
      }

      this.$f.objectToQueryString(
        _.pick(this.filters, ["country", "from_Date"])
      );

      await this.getAllOrdersBySeller({});
      this.loadingFind = false;
    },
    async reset() {
      this.paginate.currentpage = 1;
      this.intervalDates.from_Date =
        this.intervalDates.to_Date =
        this.filters.from_Date =
        this.filters.to_Date =
          null;
      // await this.getOrdersBySeller(this.formData.seller,'seller');
      // await this.getTotalOrders();

      this.filters.seller = this.formData.seller = null;
      this.orders = [];
      this.$f.objectToQueryString(_.pick(this.filters, ["country"]));
      await this.getAllOrdersBySeller({});
    },
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }

      this.filters.page = page;
      this.filters.limit = this.limit;
      this.getOrders(this.filters);
    },
  },
};
</script>

<style scoped>
.mt-2 {
  margin-top: 0.5rem !important;
}
.border-top {
  border-top: 1px dashed #8d9091 !important;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.align-middle {
  vertical-align: middle !important;
}
.invoice-table tbody:last-child {
  border: none;
}
.invoice-table .table > tbody {
  vertical-align: inherit;
}
.invoice-table .table-nowrap td,
.invoice-table .table-nowrap th {
  white-space: nowrap;
}
.invoice-table .table th {
  font-weight: 500;
  text-align: left;
  font-size: 15px;
}
.invoice-table .table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.invoice-table .table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.25rem;
}
.invoice-table .bg-light {
  background-color: #f3f6f9;
}

.invoice-table .border-0 {
  border: 0 !important;
}
.invoice-table .form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f3f6f9;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.siz12 {
  font-size: 12px;
}
.loading-amount svg {
  width: 20px;
}
.empty-row {
  background: repeating-linear-gradient(
    53deg,
    #bfc0c2 4%,
    #fcfcff 11px 6%,
    #ffffff 10px 7%,
    #9d9c9c 15px
  );
}
.item-tab {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10%) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}
.btn-16::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.custom-btn {
  height: 40px;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.total-amount svg {
  width: 35px;
}
.text-xss {
  font-size: 11px;
}
.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
.flag-img {
  display: inline-block;
  height: 16px;
  vertical-align: text-top;
  line-height: 16px;
  margin-right: 5px;
}
.tag_cities {
  width: 100%;
  max-width: 100%;
}
.vs__dropdown-toggle {
  border: none;
}
.tag_cities .tag {
  background: rgb(49 130 206 / 16%);
  border-radius: 20px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 28px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  padding-right: 0;
}
.vs-chip--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10px;
}
.vs-chip--close {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tag_cities .tag [data-role="remove"]::after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}
.tag_cities .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.top-100 {
  top: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.max-h-select {
  max-height: 300px;
}
[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.form-payement {
  min-height: 400px;
}

dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
</style>
