var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', {
    staticClass: "item-tab"
  }, [_c('tr', [_c('td', {
    staticClass: "border-t-2 border-gray-300 px-3",
    attrs: {
      "rowspan": "2"
    }
  }, [_c('label', {
    staticClass: "text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
  }, [_c('input', {
    staticClass: "form-checkbox rowCheckbox focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "checkbox",
      "id": _vm.order.order._id
    },
    on: {
      "click": function click($event) {
        return _vm.getRowDetail($event, _vm.order, _vm.order.order._id);
      }
    }
  })])]), _c('td', {
    staticClass: "border-t-2 border-r-2 border-l-2 border-gray-300"
  }, [_c('div', {
    staticClass: "align-middle inline-block min-w-full overflow-hidden bg-white shadow-dashboard px-4 pt-2 rounded-bl-lg rounded-br-lg"
  }, [_c('table', {
    staticClass: "min-w-full"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" ID order ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("country")) + " ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("traking_number")) + " ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("details")) + " ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("Products name")) + " ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _c('th', {
    staticClass: "px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + " ")])])]), _c('tbody', {
    staticClass: "bg-white"
  }, [_c('tr', [_c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', [_c('div', {
    staticClass: "text-sm leading-5 text-gray-800"
  }, [_vm._v(" " + _vm._s(_vm.order.order.id) + " ")])])])]), _c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap"
  }, [_c('div', {
    staticClass: "text-sm leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, _vm.order.country)) + " ")])]), _c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap"
  }, [_c('div', {
    staticClass: "text-sm leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.order.trackingNumber) + " ")])]), _c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap 0"
  }, [_c('div', {
    staticClass: "text-sm leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.TotalProductOrder()) + " " + _vm._s(_vm.$t("products")) + " ")])]), _c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap 0"
  }, [_c('div', {
    staticClass: "text-sm leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.sumProduct(_vm.order.order.details)) + " ")])]), _c('td', {
    staticClass: "px-3 py-1 whitespace-no-wrap"
  }, [_c('div', {
    staticClass: "text-sm leading-5 inline-block px-2 text-center rounded-full text-xs text-white cursor-pointer font-medium",
    class: _vm.order.status == 'paid' ? 'bg-green-600' : 'bg-orange-600'
  }, [_vm._v(" " + _vm._s(_vm.order.status) + " ")])]), _c('td', {
    staticClass: "px-3 py-1 text-right whitespace-no-wrap"
  }, [_c('div', {
    staticClass: "text-sm leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.order.order.totalPrice) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.order.currency || _vm.idWharhouse.currency) + ")")]) : _vm._e(), _vm.order.status == 'return' ? _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("(Order " + _vm._s(_vm.order.isCancelled ? "Cancelled" : "Refused") + ")")]) : _vm._e()])])])])])])]), _c('td', {
    staticClass: "border-t-2 border-gray-300 border-r-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.quality_control,
      expression: "quality_control"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "quality_control",
      "disabled": "",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.quality_control
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.quality_control = $event.target.value;
      }, _vm.changedVat]
    }
  })]), _c('td', {
    staticClass: "border-t-2 border-gray-300 border-r-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.confirmation_vat,
      expression: "confirmation_vat"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "confirmation",
      "disabled": "",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.confirmation_vat
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.confirmation_vat = $event.target.value;
      }, _vm.changedVat]
    }
  })]), _c('td', {
    staticClass: "border-t-2 border-gray-300 border-r-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fulfillement_fees_vat,
      expression: "fulfillement_fees_vat"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "confirmation",
      "disabled": "",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fulfillement_fees_vat
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.fulfillement_fees_vat = $event.target.value;
      }, _vm.changedVat]
    }
  })]), _c('td', {
    staticClass: "border-t-2 border-gray-300 border-r-2",
    class: _vm.shipping_vat != 0 ? '' : 'empty-row'
  }, [_vm.shipping_vat != 0 ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.shipping_vat,
      expression: "shipping_vat"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "shipping_fees",
      "disabled": "",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.shipping_vat
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.shipping_vat = $event.target.value;
      }, _vm.changedVat]
    }
  }) : _vm._e()]), _c('td', {
    staticClass: "border-t-2 border-gray-300 border-r-2",
    class: _vm.order.isRefunded ? '' : 'empty-row'
  }, [_vm.order.isRefunded ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.shipping_refund_vat,
      expression: "shipping_refund_vat"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "disabled": "",
      "id": "refund",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.shipping_refund_vat
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.shipping_refund_vat = $event.target.value;
      }, _vm.changedVat]
    }
  }) : _vm._e()]), _c('td', {
    staticClass: "border-t-2 border-gray-300",
    class: _vm.order.isCancelled ? '' : 'empty-row'
  }, [_vm.order.isCancelled ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.shipping_cancel_vat,
      expression: "shipping_cancel_vat"
    }],
    staticClass: "py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "disabled": "",
      "id": "Cancelled",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.shipping_cancel_vat
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.shipping_cancel_vat = $event.target.value;
      }, _vm.changedVat]
    }
  }) : _vm._e()])]), _c('tr', [_c('td', {
    staticClass: "border-t-2 border-l-2 border-r-2 border-gray-300 px-4 text-right"
  }, [_c('div', {
    staticClass: "font-bold leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.$t("revenue")) + ": "), _c('span', {
    staticClass: "text-green-600"
  }, [_vm._v(_vm._s(_vm.TotalAmont()) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.order.currency || _vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('td', {
    staticClass: "px-4 py-2 whitespace-no-wrap border-t-2 text-right",
    attrs: {
      "colspan": "6"
    }
  }, [_c('div', {
    staticClass: "font-bold leading-5 text-blue-900"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_fees")) + ": "), _c('span', {
    staticClass: "text-green-600"
  }, [_vm._v(_vm._s(_vm.totalfees) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.order.currency || _vm.idWharhouse.currency) + ")")]) : _vm._e()])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }