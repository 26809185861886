<template>
  <tbody class="item-tab relative total-amount loidingconfirm">
    <loading
      v-if="sellerSelect === orders._id.seller[0]._id.toString()"
      :active.sync="loadingShowPay"
      :is-full-page="fullPage"
    ></loading>
    <tr class="border-b border-gray-200 hover:bg-gray-100">
      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
        <div class="text-xs leading-5 capitalize rounded-full">
          <div class="font-bold text-gray-700">
            <span
              ><i class="material-icons pr-1 tiny">contacts</i
              ><span>{{ orders._id.seller[0].fullName }}</span></span
            >
          </div>
          <!-- <div class="font-light text-gray-700">
                   <span><i class="material-icons tiny">email</i><span>{{orders._id.seller[0].email}}</span></span>
                </div> -->
        </div>
      </td>
      <td
        class="px-3 py-1 whitespace-no-wrap border-b border-gray-300 text-center"
      >
        <div class="flex leading-5 capitalize rounded-full">
          <span v-for="(countr, r) in orders.country || country" :key="r">
            <img
              :src="$f.getCountryCode(countr)"
              class="w-4 h-4 rounded-full mr-2"
            />
          </span>
        </div>
      </td>
      <td
        class="px-3 py-1 whitespace-no-wrap border-b border-gray-300 text-center"
      >
        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
          {{ orders.count }}
        </div>
      </td>
      <td
        class="px-3 text-center py-1 whitespace-no-wrap border-b border-gray-300"
      >
        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
          <!-- <span v-if="orders.data && orders.data.length>1"> -->
          <span v-for="(price, j) in orders.data" :key="j">
            {{ price.amount.toFixed(2) }} <sup>{{ price.currency }}</sup>
            <span v-if="j != orders.data.length - 1">|</span>
          </span>
          <!-- </span> -->
          <!-- <span v-else>{{ orders.amount.toFixed(2)}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup></span> -->
        </div>
      </td>
      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
        <div class="flex justify-center">
          <div class="text-black opacity-75 cursor-pointer hover:text-blue-500">
            <div @click="getOrdersBySeller(orders._id.seller[0], 'seller')">
              <i class="material-icons mx-2"> remove_red_eye</i>
            </div>
          </div>
          <button
            @click="
              OpenClosePopPayMethode('open', 'allOrders', orders._id.seller[0])
            "
            class="bg-gray-800 text-xs text-white px-5 py-1 font-semibold rounded uppercase hover:bg-gray-700 relative loading-amount"
            :class="loadingShowPay ? 'disabled-loading' : ''"
          >
            Pay All
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    item: Object,
    loadingShowPay: Boolean,
    idWharhouse: Object,
    citiesPrices: { type: Array },
    sellerSelect: String,
    warehouse: String,
    country: { type: Array },
  },
  data: function () {
    return {
      orders: this.item,
      amountBySeller: 0,
      fullPage: false,
      loading: false,
    };
  },
  async mounted() {
    //await this.getTotalAmountByseller(this.orders._id.seller[0]);
  },
  methods: {
    OpenClosePopPayMethode: async function (action, type, order) {
      this.$emit("OpenClosePopPayMethode", action, type, order);
    },
    getOrdersBySeller: async function (seller, type) {
      this.$emit("getOrdersBySeller", seller, type);
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    getPriceBycity(city, array) {
      let price = 0;
      for (var i = 0; i < array.length; i++) {
        if (array[i].name.toLowerCase() === city.toLowerCase()) {
          price = array[i].price;
          break;
        }
      }
      return price;
    },
    //  async getTotalAmountByseller(seller){
    //     this.amountBySeller=0;
    //    if(seller) {
    //         this.loading=true;
    //         let filter={};let totalOrders=[];
    //         //get settings
    //         let conf_vat=0,full_vat=0,shipping_vat=0,shipping_refund_vat=0,shipping_cancel_vat=0,quality_control=0;
    //         const data = await this.$server.get("settings");
    //         const currentSettingW= this.getElementInArray(data.content.details,this.idWharhouse._id);
    //         if(currentSettingW) this.settings=currentSettingW;
    //         else this.settings= {shipping: {fees: 0},order: {feesConfirmation: 0,fulfillement_fees:0},quality_control:0}
    //         const currentSettingSeller= this.getElementInArray(seller.fees,this.idWharhouse._id);
    //         //fees confirmation
    //         if((currentSettingSeller && currentSettingSeller.confirmation == -1) || !currentSettingSeller) conf_vat= this.settings.order.feesConfirmation;
    //         else conf_vat= currentSettingSeller.confirmation;
    //          //fees fulfillement
    //         if((currentSettingSeller && currentSettingSeller.fulfillement_fees == -1) || !currentSettingSeller) full_vat= this.settings.order.fulfillement_fees;
    //         else full_vat= currentSettingSeller.fulfillement_fees;
    //         //fees shipping
    //         if((currentSettingSeller && currentSettingSeller.shipping == -1) || !currentSettingSeller) shipping_vat= this.settings.shipping.fees;
    //         else shipping_vat= currentSettingSeller.shipping;
    //         //refund shipping
    //         if((currentSettingSeller && currentSettingSeller.refund == -1) || !currentSettingSeller) shipping_refund_vat= this.settings.shipping.refund;
    //         else shipping_refund_vat= currentSettingSeller.refund;

    //         //cancelation shipping
    //         if((currentSettingSeller && currentSettingSeller.cancelation == -1) || !currentSettingSeller) shipping_cancel_vat= this.settings.shipping.cancelation;
    //         else shipping_cancel_vat= currentSettingSeller.cancelation;

    //         //qualite controle
    //         if((currentSettingSeller && currentSettingSeller.quality_control == -1) || !currentSettingSeller) quality_control= this.settings.quality_control;
    //         else quality_control= currentSettingSeller.quality_control;

    //         //check seller service
    //         const services= this.getElementInArray(seller.settings,this.idWharhouse._id);
    //         if(services && !services.confirmation) conf_vat=0;
    //         if(services && !services.fulfillement) full_vat=0;
    //         if(services && !services.shipping) {shipping_vat=shipping_refund_vat=shipping_cancel_vat=0;}

    //         //end get settings
    //            // await this.getSettings(seller);
    //             filter.seller=seller._id;
    //             filter.limit= 10000;
    //             filter.status=  'PaidOrRefunded';
    //             filter["customer.country"]= this.warehouse;
    //             const res = await this.$server.search("orders",filter);
    //             if (res.content.results) {
    //                 totalOrders = res.content.results;
    //                 for (let i in totalOrders) {
    //                     let totalfees=0;
    //                     totalfees+= conf_vat+full_vat+quality_control;
    //                     const services=this.getElementInArray(totalOrders[i].seller.settings,this.idWharhouse._id);
    //                     if(this.warehouse=="MA" && services && services.shipping && totalOrders[i].customer.city){shipping_vat=this.getPriceBycity(totalOrders[i].customer.city,this.citiesPrices)}
    //                     if(totalOrders[i].status.name =='Return') {
    //                         totalOrders[i].totalPrice = 0;
    //                         if(totalOrders[i].isRefunded && services && services.shipping) totalfees+=shipping_refund_vat;
    //                         if(totalOrders[i].isCancelled && services && services.shipping) totalfees+=shipping_cancel_vat;
    //                     }else totalfees+=shipping_vat;

    //                      this.amountBySeller+=totalOrders[i].totalPrice - totalfees;
    //                 }
    //             } else totalOrders = [];

    //              this.loading=false;
    //     }
    // },
  },
};
</script>
