<template>
  <tbody class="item-tab">
    <tr>
      <td class="border-t-2 border-gray-300 px-3" rowspan="2">
        <label
          class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
        >
          <input
            type="checkbox"
            :id="order.order._id"
            class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
            @click="getRowDetail($event, order, order.order._id)"
          />
        </label>
      </td>
      <td class="border-t-2 border-r-2 border-l-2 border-gray-300">
        <!--sous table order -->
        <div
          class="align-middle inline-block min-w-full overflow-hidden bg-white shadow-dashboard px-4 pt-2 rounded-bl-lg rounded-br-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left leading-4 text-green-600 tracking-wider"
                >
                  ID order
                </th>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("country") }}
                </th>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("traking_number") }}
                </th>

                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("details") }}
                </th>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("Products name") }}
                </th>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("status") }}
                </th>
                <th
                  class="px-4 py-1 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4 text-green-600 tracking-wider"
                >
                  {{ $t("total_price") }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr>
                <td class="px-3 py-1 whitespace-no-wrap">
                  <div class="flex items-center">
                    <div>
                      <div class="text-sm leading-5 text-gray-800">
                        {{ order.order.id }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1 whitespace-no-wrap">
                  <div class="text-sm leading-5 text-blue-900">
                    {{ $f.getCountryNameByCode(options, order.country) }}
                  </div>
                </td>
                <td class="px-3 py-1 whitespace-no-wrap">
                  <div class="text-sm leading-5 text-blue-900">
                    {{ order.trackingNumber }}
                  </div>
                </td>

                <td class="px-3 py-1 whitespace-no-wrap 0">
                  <div class="text-sm leading-5 text-blue-900">
                    {{ TotalProductOrder() }} {{ $t("products") }}
                  </div>
                </td>
                <td class="px-3 py-1 whitespace-no-wrap 0">
                  <div class="text-sm leading-5 text-blue-900">
                    {{ sumProduct(order.order.details) }}
                  </div>
                </td>
                <td class="px-3 py-1 whitespace-no-wrap">
                  <div
                    :class="
                      order.status == 'paid' ? 'bg-green-600' : 'bg-orange-600'
                    "
                    class="text-sm leading-5 inline-block px-2 text-center rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ order.status }}
                  </div>
                </td>
                <td class="px-3 py-1 text-right whitespace-no-wrap">
                  <div class="text-sm leading-5 text-blue-900">
                    {{ order.order.totalPrice }}
                    <sup v-if="idWharhouse"
                      >({{ order.currency || idWharhouse.currency }})</sup
                    >
                    <span v-if="order.status == 'return'" class="font-bold"
                      >(Order
                      {{ order.isCancelled ? "Cancelled" : "Refused" }})</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end sous table order -->
      </td>
      <td class="border-t-2 border-gray-300 border-r-2">
        <input
          id="quality_control"
          disabled
          type="number"
          min="0"
          @input="changedVat"
          v-model="quality_control"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
      <td class="border-t-2 border-gray-300 border-r-2">
        <input
          id="confirmation"
          disabled
          type="number"
          min="0"
          @input="changedVat"
          v-model="confirmation_vat"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
      <td class="border-t-2 border-gray-300 border-r-2">
        <input
          id="confirmation"
          disabled
          type="number"
          min="0"
          @input="changedVat"
          v-model="fulfillement_fees_vat"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
      <td
        :class="shipping_vat != 0 ? '' : 'empty-row'"
        class="border-t-2 border-gray-300 border-r-2"
      >
        <input
          id="shipping_fees"
          disabled
          v-if="shipping_vat != 0"
          type="number"
          min="0"
          @input="changedVat"
          v-model="shipping_vat"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
      <td
        :class="order.isRefunded ? '' : 'empty-row'"
        class="border-t-2 border-gray-300 border-r-2"
      >
        <input
          v-if="order.isRefunded"
          disabled
          id="refund"
          type="number"
          min="0"
          @input="changedVat"
          v-model="shipping_refund_vat"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
      <td
        :class="order.isCancelled ? '' : 'empty-row'"
        class="border-t-2 border-gray-300"
      >
        <input
          v-if="order.isCancelled"
          disabled
          id="Cancelled"
          type="number"
          min="0"
          @input="changedVat"
          v-model="shipping_cancel_vat"
          class="py-2 border-2 border-gray-400 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
        />
      </td>
    </tr>
    <tr>
      <td
        class="border-t-2 border-l-2 border-r-2 border-gray-300 px-4 text-right"
      >
        <div class="font-bold leading-5 text-blue-900">
          {{ $t("revenue") }}:
          <span class="text-green-600"
            >{{ TotalAmont() }}
            <sup v-if="idWharhouse"
              >({{ order.currency || idWharhouse.currency }})</sup
            ></span
          >
        </div>
      </td>
      <td
        class="px-4 py-2 whitespace-no-wrap border-t-2 text-right"
        colspan="6"
      >
        <div class="font-bold leading-5 text-blue-900">
          {{ $t("total_fees") }}:
          <span class="text-green-600"
            >{{ totalfees }}
            <sup v-if="idWharhouse"
              >({{ order.currency || idWharhouse.currency }})</sup
            ></span
          >
        </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    item: Object,
    formData: { type: Object },
    confvat: Number,
    fulvat: Number,
    ship_vat: Number,
    ship_refund_vat: Number,
    ship_cancel_vat: Number,
    qu_control: Number,
    citiesPrices: { type: Array },
    warehouse: String,
    warehouses: { type: Array },
    servicesSeller: { type: Object },
    idWharhouse: Object,
    settingsFees: Object,
    options: { type: Array },
  },
  data: function () {
    return {
      order: this.item,
      totalfees: 0,
      shippings: [],
      shippingType: "normal",
      shippingTypeChangCount: 1,
      confirmation_vat: this.confvat,
      fulfillement_fees_vat: this.fulvat,
      shipping_vat: this.ship_vat,
      shipping_refund_vat: this.ship_refund_vat,
      shipping_cancel_vat: this.ship_cancel_vat,
      quality_control: this.qu_control,
    };
  },
  async mounted() {
    const filters = {};
    // await this.getShippings(filters);
    this.OrderAddfees();
  },
  methods: {
    TotalProductOrder() {
      let totalProduct = 0;
      if (this.order.order.details.length > 0) {
        for (let p = 0; p < this.order.order.details.length; p++) {
          totalProduct += this.order.order.details[p].quantity;
        }
      }
      return totalProduct;
    },
    sumProduct(data) {
      let product = "";
      for (let i in data) {
        if (data[i].product && data[i].product.name)
          product += data[i].product.name;
        if (data.length - 1 != i) product += " |";
      }
      return product;
    },
    changedVat(event) {
      this.$emit(
        "UpdateRowFees",
        this.order.order._id,
        this.confirmation_vat,
        this.fulfillement_fees_vat,
        this.shipping_vat,
        this.shipping_refund_vat,
        this.shipping_cancel_vat,
        this.quality_control,
        this.TotalAmont()
      );
      this.OrderAddfees();
      //this.totalfeesorder=parseFloat(this.order.confirmation_vat)+parseFloat(this.order.shipping_vat)+parseFloat(this.order.shipping_refund_vat)+parseFloat(this.order.shipping_cancel_vat);
    },
    exceptText(text) {
      if (text && text.length > 40) text = text.substring(0, 40) + "...";
      return text;
    },
    getRowDetail: function ($event, order, id) {
      this.$emit("getRowDetail", $event, order, id);
    },
    getSettings(seller = null, warehouse = null) {
      const currentSettingW = this.getElementInArray(
        this.settingsFees.details,
        warehouse || this.idWharhouse._id
      );
      let settings = {
        shipping: { fees: 0 },
        order: { feesConfirmation: 0, fulfillement_fees: 0 },
        quality_control: 0,
        info: { mad: 1, currency: 1 },
      };
      if (currentSettingW) settings = currentSettingW;

      if (seller) {
        const currentSettingSeller = this.getElementInArray(
          seller.fees,
          warehouse || this.idWharhouse._id
        );
        //fees confirmation
        if (
          (currentSettingSeller && currentSettingSeller.confirmation == -1) ||
          !currentSettingSeller
        )
          this.confirmation_vat = settings.order.feesConfirmation;
        else this.confirmation_vat = currentSettingSeller.confirmation;
        //fees fulfillement
        if (
          (currentSettingSeller &&
            currentSettingSeller.fulfillement_fees == -1) ||
          !currentSettingSeller
        )
          this.fulfillement_fees_vat = settings.order.fulfillement_fees;
        else
          this.fulfillement_fees_vat = currentSettingSeller.fulfillement_fees;

        //fees shipping
        if (
          (currentSettingSeller && currentSettingSeller.shipping == -1) ||
          !currentSettingSeller
        )
          this.shipping_vat = settings.shipping.fees;
        else this.shipping_vat = currentSettingSeller.shipping;

        //Cancelation shipping
        if (
          (currentSettingSeller && currentSettingSeller.cancelation == -1) ||
          !currentSettingSeller
        )
          this.shipping_cancel_vat = settings.shipping.cancelation;
        else this.shipping_cancel_vat = currentSettingSeller.cancelation;

        //refund shipping
        if (
          (currentSettingSeller && currentSettingSeller.refund == -1) ||
          !currentSettingSeller
        )
          this.shipping_refund_vat = settings.shipping.refund;
        else this.shipping_refund_vat = currentSettingSeller.refund;

        //qualite controle
        if (
          (currentSettingSeller &&
            currentSettingSeller.quality_control == -1) ||
          !currentSettingSeller
        )
          this.quality_control = settings.quality_control;
        else this.quality_control = currentSettingSeller.quality_control;

        //check seller service
        const services = this.getElementInArray(
          seller.settings,
          warehouse || this.idWharhouse._id
        );
        if (services && !services.confirmation) this.confirmation_vat = 0;
        if (services && !services.fulfillement) this.fulfillement_fees_vat = 0;
        if (services && !services.shipping) {
          this.shipping_vat =
            this.shipping_cancel_vat =
            this.shipping_refund_vat =
              0;
        }
      }
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    getPriceBycity(city, array) {
      let price = 0;
      for (var i = 0; i < array.length; i++) {
        if (array[i].name.toLowerCase() === city.toLowerCase()) {
          price = array[i].price;
          break;
        }
      }
      return price;
    },

    TotalAmont: function () {
      return parseFloat(this.order.order.totalPrice) - this.totalfees;
    },
    async OrderAddfees() {
      const dataWarehouse = this.warehouses.find(
        (item) => item.country === this.order.country
      );
      this.getSettings(
        this.order.seller,
        dataWarehouse ? dataWarehouse._id : this.idWharhouse._id
      );
      this.order.order.confirmation_vat = this.confirmation_vat;
      this.order.order.fulfillement_fees_vat = this.fulfillement_fees_vat;
      this.order.currency = dataWarehouse
        ? dataWarehouse.currency
        : this.idWharhouse.currency;

      const services = this.getElementInArray(
        this.order.seller.settings,
        dataWarehouse ? dataWarehouse._id : this.idWharhouse._id
      );
      if (this.warehouse == "MA" && services && services.shipping) {
        this.shipping_vat = this.getPriceBycity(
          this.order.order.customer.city,
          this.citiesPrices
        );
      }
      if (this.order.status == "return") this.shipping_vat = 0;
      this.order.order.shipping_vat = this.shipping_vat;
      this.order.order.quality_control = this.quality_control;
      if (
        this.order.status == "return" &&
        this.order.isRefunded &&
        services &&
        services.shipping
      )
        this.order.order.shipping_refund_vat = this.shipping_refund_vat;
      else this.shipping_refund_vat = this.order.order.shipping_refund_vat = 0;
      if (
        this.order.status == "return" &&
        this.order.isCancelled &&
        services &&
        services.shipping
      ) {
        this.shipping_cancel_vat = this.shipping_cancel_vat;
        this.order.order.shipping_cancel_vat = this.shipping_cancel_vat;
      } else
        this.shipping_cancel_vat = this.order.order.shipping_cancel_vat = 0;
      this.totalfees =
        parseFloat(this.confirmation_vat) +
        parseFloat(this.fulfillement_fees_vat) +
        parseFloat(this.shipping_vat) +
        parseFloat(this.shipping_refund_vat) +
        parseFloat(this.shipping_cancel_vat) +
        parseFloat(this.quality_control);
    },
    // async getShippings(filter){
    //     filter.order=this.order._id;
    //    // filter.status='received';
    //     const res = await this.$server.search("shippings",filter);
    //     if(res.content.results) {
    //        this.shippings = res.content.results;
    //        this.shippingType="normal";
    //     }else this.shippings = [];
    // },
    formattedDate(date) {
      return this.$moment(date).format("yyyy-MM-DD");
    },
  },
};
</script>
<style scoped>
.text-sm {
  font-size: 12px;
}
</style>
